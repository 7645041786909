import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './assets/routes/App';
import './assets/styles/index.css';

import Data from './data.json';

ReactDOM.render(<App data={Data} />, document.getElementById('root'));

serviceWorker.unregister();
