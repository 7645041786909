import React from 'react';

import '../assets/styles/projectsItem.css';

const ProjectsItem = ({ title, description, watch, github, img }) => (
  <div className='Projects-item'>
    <img className='Projects-item__image' src={img} alt={title} />
    <h1 className='Projects-item__title'>{title}</h1>
    <p className='Projects-item__description'>{description}</p>
    <div className='Projects-item__link'>
      <a href={watch} target="_blank" className='Projects-item__link-item'>
        Watch
      </a>
      <a href={github} target="_blank" className='Projects-item__link-item'>
        GitHub
      </a>
    </div>
  </div>
);

export default ProjectsItem;
