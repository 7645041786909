import React, { useEffect } from 'react';
import {
  HashRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import Profile from '../../components/profile';
import Education from '../../components/education';
import HomeLayout from '../../components/home-layout';
import Job from '../../components/job';
import Projects from '../../components/projects';
import NotFound from '../../components/notFound';

import ReactGA from 'react-ga';

function App() {
  useEffect(() => {
    ReactGA.initialize('UA-147871134-1');
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(
      window.location.pathname + window.location.search
    );
  }, []);

  return (
    <Router>
      <HomeLayout>
        <Switch>
          <Route exact path='/' component={Profile} />
          <Route exact path='/education' component={Education} />
          <Route exact path='/job' component={Job} />
          <Route exact path='/projects' component={Projects} />
          <Route component={NotFound} />
        </Switch>
      </HomeLayout>
    </Router>
  );
}

export default App;
