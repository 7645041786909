import React from 'react';
import ItemDescription from './itemDescription';
import '../assets/styles/item.css';

const Item = ({ title, ...data }) => (
  <div className='Item'>
    <div className='Item__header'>
      <h1 className='Item__header-title'>{title}</h1>
    </div>
    <div className='Item__content'>
      {data.data.map((item) => (
        <ItemDescription key={item.id} {...item} />
      ))}
    </div>
  </div>
);

export default Item;
