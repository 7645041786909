import React from 'react';
import Menu from './menu';

function HomeLayout(props) {
  return (
    <>
      {props.children}
      <Menu />
    </>
  );
}

export default HomeLayout;
