import React, { useEffect } from 'react';
import Item from './item';
import data from '../data.json';
import ReactGA from 'react-ga';

const Education = () => {
  useEffect(() => {
    ReactGA.pageview('/education');
  }, []);
  return <Item title='Education' data={data.education} />;
};

export default Education;
