import React, { useEffect } from 'react';
import '../assets/styles/projects.css';
import ProjectsItem from './projectsItem';
import data from '../data.json';
import ReactGA from 'react-ga';

const Projects = () => {
  useEffect(() => {
    ReactGA.pageview('/projects ');
  }, []);
  return (
    <div className='Projects'>
      <div className='Projects__header'>
        <h1 className='Projects__header-title'>Projects</h1>
      </div>
      <div className='Projects__content'>
        {data.projects.map((item) => (
          <ProjectsItem key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
};

export default Projects;
