import React from 'react';
import '../assets/styles/profile.css';
import ProfileDetails from './profileDetails';
import data from '../data.json';

function Profile() {
  const photo =
    'https://avatars2.githubusercontent.com/u/4369986?s=460&v=4';
  const [state, setState] = React.useState({ data });
  return (
    <React.Fragment>
      <div className='Content'>
        <div className='Content__profile'>
          <img
            className='Content__profile-photo'
            src={photo}
            alt='profile photo'
          />
          <div className='Content__profile-title'>
            <h1>Santiago Sucerquia</h1>
            <h2>Frontend Developer</h2>
          </div>
        </div>
      </div>
      <ProfileDetails state={state} />
    </React.Fragment>
  );
}

export default Profile;
