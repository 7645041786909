import React from 'react';
import '../assets/styles/notFound.css';

const NotFound = () => (
  <div className='not-found'>
    <h1>Oop!! page not found</h1>
  </div>
);

export default NotFound;
