import React, { useEffect } from 'react';
import Item from './item';
import data from '../data.json';
import ReactGA from 'react-ga';

const Job = () => {
  useEffect(() => {
    ReactGA.pageview('/job');
  }, []);
  return <Item title='Jobs' data={data.jobs} />;
};
export default Job;
