import React from 'react';
import '../assets/styles/menu.css';
import { Link } from 'react-router-dom';

function Menu() {
  const logoPlus = 'https://imgfolders.s3.us-east-2.amazonaws.com/imgWebSite/plus.png';
  const [className, setClassName] = React.useState({
    nameMenu: 'Container Container--off',
    nameButton: 'Menu__button--img-close',
    active: false,
  });

  function setButton() {
    return className.active
      ? setClassName({ nameMenu: 'Container Container--off', nameButton: 'Menu__button--img-close', active: false })
      : setClassName({ nameMenu: 'Container', nameButton: 'Menu__button--img-open', active: true });
  }
  return (
    <React.Fragment>
      <div className={className.nameMenu}>
        <div className='Menu'>
          <Link to='/'>
            <div className='Menu__button--option'>
              <img src='https://imgfolders.s3.us-east-2.amazonaws.com/imgWebSite/home.png' alt='' />
            </div>
            <p>Home</p>
          </Link>
          <Link to='/projects'>
            <div className='Menu__button--option'>
              <img src='https://imgfolders.s3.us-east-2.amazonaws.com/imgWebSite/portfolio.png' alt='' />
            </div>
            <p>Projects</p>
          </Link>
          <Link to='/job'>
            <div className='Menu__button--option'>
              <img src='https://imgfolders.s3.us-east-2.amazonaws.com/imgWebSite/job.png' alt='' />
            </div>
            <p>Jobs</p>
          </Link>
          <Link to='/education'>
            <div className='Menu__button--option'>
              <img src='https://imgfolders.s3.us-east-2.amazonaws.com/imgWebSite/educ.png' alt='' />
            </div>
            <p>Studies</p>
          </Link>
        </div>
      </div>
      <div className='Menu__container'>
        <button onClick={setButton} className='Menu__button'>
          <img className={className.nameButton} src={logoPlus} />
        </button>
      </div>
    </React.Fragment>
  );
}

export default Menu;
