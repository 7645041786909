import React from 'react';
import '../assets/styles/profileDetails.css';
import SocialNetWork from './socialNetWork';

const ProfileDetails = (props) => (
  <div className='Profile-content'>
    <div className='Profile__details'>
      <div className='Profile__details-content'>
        <p>
          Hello, I'm a young front-end developer from Medellin city, always looking for personal growth. I like to be in search of new
          references, good books, everything that allows me to be better every day and well, to be able to give you some of what I learned
          to my friends and colleagues.
        </p>
        <p>
          As a frontend developer I have experience in the ReactJS framework, state management, route management with React Routes,
          knowledge of agile methodologies as well as javascript, code versioning such as git and github.
        </p>
        <p>
          I'm one of the people who not only seeks to tighten nuts and bolts, I like to develop new things, understand how they function and
          the most important thing is that I can carry it out through code.
        </p>
      </div>
      <div className='Profile__details-netWork'>
        {props.state.data.social.map((item) => (
          <SocialNetWork key={item.id} {...item} />
        ))}
      </div>
      <a href='https://1drv.ms/b/s!AqDOgjyEm5JRhXeeV9yEvBfsUXlu?e=KELz5G' className='Profile__button' target='blank'>
        Download CV
      </a>
    </div>
  </div>
);

export default ProfileDetails;
