import React from 'react';

import '../assets/styles/socialNetWork.css';

const SocialNetWork = ({ title, image, url }) => (
  <div className='Social-netWork'>
    <a href={url} target="_blank">
      <img src={image} alt={title} />
    </a>
  </div>
);

export default SocialNetWork;
