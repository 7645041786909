import React from 'react';

import '../assets/styles/itemDescription.css';

const ItemDescription = ({ title, academy, timeLine, img, description }) => (
  <div className='Item-description'>
    <img className='Item-description__image' src={img} alt={academy} />
    <h1 className='Item-description__title'>{title}</h1>
    <h3>{timeLine}</h3>
    <p className='Item-description__description'>{description}</p>
  </div>
);

export default ItemDescription;
